import { useState, useEffect } from "react";
import { Welcome } from "../../../../components/common/welcome/index";
import { Button } from "../../../../components/common/button/index";
import { Input } from "../../../../components/common/input/index";
import { useStyle } from "./edu-needs.style";
import Grid from "@material-ui/core/Grid";
import Icon from "@material-ui/core/Icon";
import { InputRadioButton } from "../../../../components/common/input-radio-button-two/index";
import { Box } from "@material-ui/core";
import request, { ParentUser } from "../../../../utils/authUtil";
import { Loader } from "../../../../components/common/loader/index";
import { ProgressBar } from "../../../../components/common/progress-bar/index";
import { Upload } from "../../../../components/common/upload/index";
import axios from "axios";
import { Type } from "../../../../utils/document-types";
import { DownLoad } from "../../../../components/common/download";

type Props = {
  nextPage?: any;
  prevPage?: any;
  stdAppId: string;
};

interface eduNeedsData {
  education_uuid: string;
  question: string;
  answer: boolean | string;
  answer_uuid: string;
  further_detail_required: boolean;
  follow_up_supporting_doc: boolean;
  follow_up_question: string;
  followup_answer: string;
  followup_answer_uuid: string;
  followup_education_uuid: string;
  supported_doc_url: string;
  isFile: boolean;
  isUploaded: boolean;
  fileName: string;
  document_presingne_url: string;
}

const EducationNeeds = (props: Props) => {
  const styles = useStyle();
  const { nextPage, prevPage, stdAppId } = props;
  const [loader, setLoader] = useState<boolean>(false);
  const schoolID = localStorage.getItem("school-id");
  const parentID = ParentUser.uuid;
  const [eduNeeds, setEduNeeds] = useState<eduNeedsData[]>([
    {
      education_uuid: "",
      question: "",
      answer: "",
      answer_uuid: "",
      follow_up_supporting_doc: false,
      further_detail_required: false,
      follow_up_question: "",
      followup_answer: "",
      followup_answer_uuid: "",
      followup_education_uuid: "",
      supported_doc_url: "",
      isFile: false,
      isUploaded: false,
      fileName: "",
      document_presingne_url: "",
    },
  ]);

  const [error, setError] = useState<any>([]);

  const getEduNeeds = () => {
    setLoader(true);
    request({
      url: `student-enrolment/student-enrol-education-details/?student_app_uuid=${stdAppId}&school_uuid=${schoolID}`,
      method: "get",
    }).then((response) => {
      if (response.data.status_code === 1) {
        if (Object.keys(response.data?.results).length !== 0) {
          const temp: any = [];
          response.data.results.map((ques: any, index: number) => {
            temp.push({
              education_uuid: ques.education_uuid,
              question: ques.question,
              further_detail_required: ques.further_detail_required,
              followup_education_uuid: ques.followup_education_uuid_uuid,
              follow_up_question: ques.follow_up_question,
              follow_up_supporting_doc: ques.follow_up_supporting_doc,
              answer_uuid: ques.answer_uuid,
              answer: ques.answer,
              followup_answer_uuid:
                ques.answer === "" ? "" : ques.followup_answer_uuid,
              followup_answer: ques.answer === "" ? "" : ques.followup_answer,
              supported_doc_url: ques.supported_doc_url,
              isFile: ques.supported_doc_url !== "",
              isUploaded: ques.supported_doc_url !== "",
              fileName: ques.document_name,
              document_presingne_url: ques.document_presingne_url,
            });
          });
          setEduNeeds(temp);
        }
        setLoader(false);
      } else {
        setLoader(false);
      }
    });
  };

  useEffect(() => {
    getEduNeeds();
  }, []);

  const handleRadioChange = (name: boolean, index: number) => {
    const temp = [...eduNeeds];
    const tempErr = [...error];
    const val = tempErr.indexOf(`empty${index}`);
    if (index > -1) {
      tempErr.splice(val, 1);
    }
    setError(tempErr);
    if (name === false) {
      temp[index].answer = false;
      setEduNeeds(temp);
    }
    if (name === true) {
      temp[index].answer = true;
      setEduNeeds(temp);
    }
  };

  const onSubmit = () => {
    const temp = [...error];
    try {
      eduNeeds.map((item: any, index: any) => {
        if (item.answer === "") {
          if (!temp.includes(`empty${index}`)) {
            temp.push(`empty${index}`);
          }
        }
        if (
          item.answer === true &&
          item.further_detail_required === true &&
          item.followup_answer.length === 0
        ) {
          if (!temp.includes(`follow${index}`)) {
            temp.push(`follow${index}`);
          }
        }
        // else if (temp.includes(`follow${index}`)) {
        //   const val = temp.indexOf(`follow${index}`);
        //   if (index > -1) {
        //     temp.splice(val, 1);
        //   }
        // }
      });
      if (temp.length !== 0) {
        throw temp;
      } else {
        setError([]);

        setLoader(true);
        const data = eduNeeds.map((item: any) => {
          let follow_up = "";
          let follow_up_id = item.answer_uuid || "";
          if (item.further_detail_required && item.answer) {
            follow_up = item.followup_answer;
          }
          return {
            education_uuid: item.education_uuid,
            answer: item.answer,
            answer_uuid: follow_up_id,
            followup_answer_uuid: item.followup_answer_uuid,
            followup_answer: follow_up,
            supported_doc_url: item.supported_doc_url,
          };
        });
        request({
          url: `student-enrolment/student-enrol-education-details/?student_app_uuid=${stdAppId}&school_uuid=${schoolID}`,
          method: "put",
          data: data,
        }).then((response) => {
          if (response.data.status_code === 1) {
            setLoader(false);
            nextPage();
          } else {
            setLoader(false);
          }
        });
      }
    } catch (temp: any) {
      setError(temp);
    }
  };

  const deleteDocument = (id: any, index: number) => {
    setLoader(true);
    const temp = [...eduNeeds];
    temp[index].supported_doc_url = "";
    setEduNeeds(temp);
    const data = eduNeeds.map((item: any) => {
      let follow_up = "";
      let follow_up_id = item.answer_uuid || "";
      if (item.further_detail_required && item.answer) {
        follow_up = item.followup_answer;
      }
      return {
        education_uuid: item.education_uuid,
        answer: item.answer,
        answer_uuid: follow_up_id,
        followup_answer_uuid: item.followup_answer_uuid,
        followup_answer: follow_up,
        supported_doc_url: item.supported_doc_url,
      };
    });
    request({
      url: `student-enrolment/student-enrol-education-details/?student_app_uuid=${stdAppId}&school_uuid=${schoolID}`,
      method: "put",
      data: data,
    }).then((response) => {
      if (response.data.status_code === 1) {
        getEduNeeds();
        setLoader(false);
      } else {
        setLoader(false);
      }
    });
  };

  const onChange = (item: any, value: any, ind: any) => {
    const xyz = [...eduNeeds];
    const tempErr = [...error];
    xyz[ind].followup_answer = value;
    setEduNeeds(xyz);
    if (
      item.answer === true &&
      item.further_detail_required === true &&
      item.followup_answer.length === 0
    ) {
      if (!tempErr.includes(`follow${ind}`)) {
        tempErr.push(`follow${ind}`);
      }
    } else if (tempErr.includes(`follow${ind}`)) {
      const val = tempErr.indexOf(`follow${ind}`);
      if (val > -1) {
        tempErr.splice(val, 1);
      }
    }
    setError(tempErr);
  };

  const getSignedUrl = async (
    schoolId: any,
    userId: any,
    documentType: any,
    fileName: any,
    index: number
  ) => {
    const temp: any = [];
    if (fileName) {
      if (!fileName.match(/\.(jpg|jpeg|pdf|png)$/)) {
        if (!temp.includes(`doc${index}`)) {
          temp.push(`doc_accept${index}`);
        }
        setError(temp);
        return null;
      } else {
        setLoader(true);
        const res = await request({
          url: "/auth/parent-documents-upload-credentials",
          method: "post",
          data: {
            user_uuid: userId,
            school_uuid: schoolId,
            document_type: documentType,
            file_name: fileName,
          },
        });
        if (temp.includes(`doc_accept${index}`)) {
          const val = temp.indexOf(`doc_accept${index}`);
          if (index > -1) {
            temp.splice(val, 1);
          }
        }
        setError(temp);
        return res;
      }
    } else {
      return null;
    }
  };

  const awsUploadFile = async (url: any, fields: any, file: any) => {
    const formData = new FormData();
    formData.append("key", fields.key);
    formData.append("policy", fields.policy);
    formData.append("x-amz-algorithm", fields["x-amz-algorithm"]);
    formData.append("x-amz-credential", fields["x-amz-credential"]);
    formData.append("x-amz-date", fields["x-amz-date"]);
    formData.append("x-amz-signature", fields["x-amz-signature"]);
    formData.append("file", file);
    const res = await axios.post(url, formData, {
      headers: { "Content-Type": "multipart/form-data" },
    });
    return res;
  };

  const change = (event: any, index: any) => {
    new Promise((res, rej) => {
      getSignedUrl(
        schoolID,
        parentID,
        Type.other,
        event.target?.files[0]?.name,
        index
      ).then((response) => {
        setLoader(false);
        if (response === null) {
          return;
        }
        if (response.data.status_code === 1) {
          res(response.data.results);
        } else if (response.data.status_code === -1) {
          rej(response.data);
        }
      });
    }).then((response: any) => {
      awsUploadFile(response.url, response.fields, event.target.files[0]).then(
        (r) => {
          const temp = [...eduNeeds];
          temp[
            index
          ].supported_doc_url = `${response.url}${response.fields.key}`;
          temp[index].isFile = true;
          temp[index].fileName = event.target.files[0].name;
          setEduNeeds(temp);
          setLoader(false);
        }
      );
    });
  };

  const initialState = (index: number) => {
    const temp = [...eduNeeds];
    temp[index].supported_doc_url = "";
    temp[index].isFile = false;
    temp[index].fileName = "";
    setEduNeeds(temp);
  };

  return (
    <div className={styles.container}>
      <Loader open={loader} />
      <div>
        <div className={styles.bodyContainer}>
          <Grid container justifyContent="center">
            <div className={styles.card}>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <div>
                  <p>
                    <Icon onClick={prevPage} style={{ cursor: "pointer" }}>
                      arrow_back
                    </Icon>
                  </p>
                  <div>Enrolment</div>
                </div>
                <ProgressBar total={7} steps={6} />
              </Box>
              <div style={{ marginBottom: 30 }}>
                <Welcome
                  title="Educational needs"
                  description="Next: Anti-Bullying Policy"
                />
              </div>
              <Grid container direction="column">
                {eduNeeds.map((item: any, index: number) => (
                  <Box mb="15px">
                    <InputRadioButton
                      label={item.question}
                      placeHolderOne="No"
                      placeHolderTwo="Yes"
                      valueTwo={true}
                      valueOne={false}
                      answer={item.answer}
                      onChange={handleRadioChange}
                      index={index}
                      error={error.includes(`empty${index}`)}
                    />
                    {item.answer && item.further_detail_required ? (
                      <Box>
                        <Input
                          label={item.follow_up_question}
                          onChange={(e: any) =>
                            onChange(item, e.target.value, index)
                          }
                          value={item.followup_answer}
                          error={error.includes(`follow${index}`)}
                        />
                      </Box>
                    ) : null}
                    {item.follow_up_supporting_doc &&
                    item.answer &&
                    item.further_detail_required ? (
                      !item.isUploaded ? (
                        <Upload
                          id={`edu_needs${index}`}
                          label="Upload supporting document"
                          placeholder="Upload"
                          file={item.fileName}
                          onChange={(e: any) => change(e, index)}
                          isFile={item.isFile}
                          error={error.includes(`doc_accept${index}`)}
                          errorMessage={
                            error.includes(`doc_accept${index}`)
                              ? "Only .jpg .jpeg .png pdf files are allowed"
                              : "required"
                          }
                          onDelete={() => initialState(index)}
                        />
                      ) : (
                        <DownLoad
                          id={`doc_download${index}`}
                          label="View document*"
                          file={item.fileName}
                          onDelete={() => deleteDocument(`${index}`, index)}
                          downloadLink={item.document_presingne_url}
                        />
                      )
                    ) : null}
                  </Box>
                ))}

                <Grid
                  container
                  direction="row"
                  wrap="nowrap"
                  justifyContent="space-around"
                >
                  <div className={styles.buttonWidth}>
                    <Button
                      label="Next"
                      light
                      endIcon="navigate_next"
                      onClick={onSubmit}
                    />
                  </div>
                </Grid>
              </Grid>
            </div>
          </Grid>
        </div>
      </div>
    </div>
  );
};
export default EducationNeeds;
