import React, { useState, useEffect } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import Icon from "@material-ui/core/Icon";
import { Box } from "@material-ui/core";
import { useStyle } from "./view-doc.style";
import Dialog from "@material-ui/core/Dialog";
import { Loader } from "../loader/index";
import { ImageLoader } from "../image-loader/index";
import { AdminButton } from "../admin-button";

type DocProps = {
  url?: any;
  docName: string;
  openDoc: boolean;
  setOpen: any;
  htmlString?: string;
};

export const ViewDoc: React.FC<DocProps> = ({
  url,
  docName,
  openDoc,
  setOpen,
  htmlString = "",
}) => {
  const [numPages, setNumPages] = useState<any>(null);
  const [scale, setScale] = useState(0.33);
  const classes = useStyle();
  const [imgLoad, setImgLoad] = useState(true);
  const onDocumentLoadSuccess = ({ numPages }: any) => {
    setNumPages(numPages);
  };

  const handleClose = () => {
    setOpen(false);
    setNumPages(null);
    setImgLoad(true);
  };

  const getWidth = () => {
    return {
      dynamicWidth: window.screen.width,
    };
  };

  const downloadHandler = (url: string) => {
    window.open(url, "_admin");
  };

  useEffect(() => {
    var width = getWidth();
    if (width.dynamicWidth < 480) {
      setScale(0.33);
    }
    if (width.dynamicWidth > 480 && width.dynamicWidth <= 600) {
      setScale(0.4);
    }
    if (width.dynamicWidth > 600 && width.dynamicWidth <= 767) {
      setScale(0.5);
    }
    if (width.dynamicWidth > 767 && width.dynamicWidth <= 1024) {
      setScale(0.7);
    }
    if (width.dynamicWidth > 1024) {
      setScale(1);
    }
  }, []);

  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

  return (
    <>
      {openDoc && docName.match(/\.(pdf|PDF)$/) ? (
        <Dialog
          fullWidth={true}
          maxWidth="lg"
          open={openDoc}
          fullScreen={scale <= 0.7}
        >
          <Box position="fixed" style={{ zIndex: 10000 }}>
            <Icon
              onClick={handleClose}
              style={{
                padding: "10px",
                cursor: "pointer",
              }}
            >
              close
            </Icon>
          </Box>
          <div style={{ textAlign: "right", margin: "20px 20px 0 0" }}>
            <AdminButton
              startIcon="download_icon"
              className={classes.adminButton}
              label="Download"
              onClick={() => downloadHandler(url)}
            />
          </div>
          <div className={classes.root}>
            <div style={{ marginTop: "50px" }}>
              <Document
                loading={<Loader />}
                file={url}
                onLoadSuccess={onDocumentLoadSuccess}
              >
                {Array.apply(null, Array(numPages))
                  .map((x, i) => i + 1)
                  .map((page, index) => (
                    <Box minWidth="200px" key={`${page}${index}`}>
                      <Page pageNumber={page} scale={scale} />
                      <Box
                        textAlign="center"
                        marginTop="10px"
                        marginBottom="20px"
                      >
                        Page number {page} of {numPages}
                      </Box>
                      <br></br>
                    </Box>
                  ))}
              </Document>
            </div>
          </div>
        </Dialog>
      ) : null}
      {openDoc && docName.match(/\.(jpg|JPG|JPEG|jpeg|png|PNG)$/) ? (
        <Dialog open={openDoc}>
          <Box width="600px" height="600px">
            <Icon
              onClick={handleClose}
              style={{
                padding: "10px",
                cursor: "pointer",
              }}
            >
              close
            </Icon>
            <div style={{ textAlign: "right", margin: "20px 20px 0 0" }}>
              <AdminButton
                startIcon="download_icon"
                className={classes.adminButton}
                label="Download"
                onClick={() => downloadHandler(url)}
              />
            </div>
            <div className={classes.imageContainer}>
              {imgLoad ? <ImageLoader /> : null}
              <div style={{ display: imgLoad ? "none" : "block" }}>
                <img
                  src={url}
                  alt="loading.."
                  className={classes.image}
                  onLoad={() => {
                    setImgLoad(false);
                  }}
                />
              </div>
            </div>
          </Box>
        </Dialog>
      ) : null}
      {openDoc && docName.match("html") ? (
        <Dialog open={openDoc}>
          <Box width="600px" height="600px">
            <Icon
              onClick={handleClose}
              style={{
                padding: "10px",
                cursor: "pointer",
              }}
            >
              close
            </Icon>
            <div>
              <div dangerouslySetInnerHTML={{ __html: htmlString }}></div>
            </div>
          </Box>
        </Dialog>
      ) : null}
    </>
  );
};
