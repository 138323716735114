import { useState, useEffect } from "react";
import { useStyle } from "./document-upload.style";
import { Loader } from "../../../../components/common/loader";
import { ProgressBar } from "../../../../components/common/progress-bar/index";
import { Welcome } from "../../../../components/common/welcome/index";
import { Button } from "../../../../components/common/button/index";
import { Input } from "../../../../components/common/input/index";
import Grid from "@material-ui/core/Grid";
import Icon from "@material-ui/core/Icon";
import { Box } from "@material-ui/core";
import { getDocument, postDocument } from "./document-upload.api";
import {
  getParentSignature,
  getDate,
} from "../../application/document-page/document-page.api";
import { Upload } from "../../../../components/common/upload/index";
import axios from "axios";
import { DownLoad } from "../../../../components/common/download";
import request, { ParentUser } from "../../../../utils/authUtil";
import { Type } from "../../../../utils/document-types";
import Image from "../../image-checkbox/index";
import { InputDatePicker } from "../../../../components/common/date-picker";
import { InputRadio } from "../../../../components/common/input-radio/index";

type Props = {
  nextPage?: any;
  prevPage?: any;
  stdAppId: string;
};

interface schoolDocument {
  app_document_uuid: string;
  document_name: string;
  document_presingned_url: string;
  document_question: string;
  document_url: string;
  follow_up_answer: string;
  follow_up_question: string;
  further_detail_required: boolean;
}

const DocumentInfo = (props: Props) => {
  const parentID = ParentUser.uuid;
  const styles = useStyle();
  const { nextPage, prevPage, stdAppId } = props;
  const [loader, setLoader] = useState<boolean>(false);
  const schoolID = localStorage.getItem("school-id");
  const [schoolData, setSchoolData] = useState<schoolDocument[]>([
    {
      app_document_uuid: "",
      document_name: "",
      document_presingned_url: "",
      document_question: "",
      document_url: "",
      follow_up_answer: "",
      follow_up_question: "",
      further_detail_required: false,
    },
  ]);
  const [signature, setSignature] = useState<string>("");
  const [consentDate, setConsentDate] = useState(new Date());
  const [checked, setChecked] = useState({
    signature: false,
    consent: false,
  });
  const [validationError, setValidationError] = useState<string[]>([]);
  const [consent, setConsent] = useState("");

  const [validFields, setValidFields] = useState({
    isFile: false,
    isAccept: "",
    index: "-1",
  });
  const [er, setEr] = useState<any>({
    document: false,
    accept_certificate: false,
    index: "",
  });

  const [downError, setDownError] = useState<string[]>([]);

  useEffect(() => {
    setLoader(true);
    getDocument(stdAppId, schoolID).then((response) => {
      if (response.data.results.length > 0) {
        setSchoolData(response.data.results);
      }
      setLoader(false);
    });
  }, [stdAppId, schoolID]);

  useEffect(() => {
    getParentSignature(parentID).then((res) => {
      setSignature(res.data.results.document_url);
    });
    getDate().then((res) => {
      setConsentDate(new Date(res.data.results));
    });
  }, [parentID]);

  useEffect(() => {
    setLoader(true);
    request({
      url: `student-application/student-school-application-consent/?school_uuid=${schoolID}`,
      method: "get",
    }).then((response) => {
      if (response.data.status_code === 1) {
        setConsent(response.data.results.document_upload_page_consent);
        setLoader(false);
      } else {
        setLoader(false);
      }
    });
  }, []);

  const getSignedUrl = async (
    schoolId: any,
    userId: any,
    documentType: any,
    fileName: any,
    index: any
  ) => {
    if (fileName) {
      const temp = [...downError];
      if (!fileName.match(/\.(jpg|jpeg|png|pdf|PDF|JPG|JPEG|PNG)$/)) {
        if (!temp.includes(`${index}`)) {
          temp.push(`${index}`);
        }

        setDownError(temp);
        setValidFields({
          ...validFields,
          isFile: false,
          isAccept: "acceptError",
          index: `${index}`,
        });

        setEr({
          ...er,
          document: false,
          accept_certificate: true,
          index: `${index}`,
        });
        return null;
      } else {
        if (temp.includes(`${index}`)) {
          const val = temp.indexOf(`${index}`);
          if (index > -1) {
            temp.splice(val, 1);
          }
        }
        setDownError(temp);
        setLoader(true);
        const res = await request({
          url: "/auth/parent-documents-upload-credentials",
          method: "post",
          data: {
            user_uuid: userId,
            school_uuid: schoolId,
            document_type: documentType,
            file_name: fileName,
          },
        });
        return res;
      }
    } else {
      return null;
    }
  };

  const awsUploadFile = async (url: any, fields: any, file: any) => {
    const formData = new FormData();
    formData.append("key", fields.key);
    formData.append("policy", fields.policy);
    formData.append("x-amz-algorithm", fields["x-amz-algorithm"]);
    formData.append("x-amz-credential", fields["x-amz-credential"]);
    formData.append("x-amz-date", fields["x-amz-date"]);
    formData.append("x-amz-signature", fields["x-amz-signature"]);
    formData.append("file", file);
    const res = await axios.post(url, formData, {
      headers: { "Content-Type": "multipart/form-data" },
    });
    return res;
  };

  const change = (event: any, id: string, index: any) => {
    new Promise((res, rej) => {
      getSignedUrl(
        schoolID,
        parentID,
        Type.other,
        event.target?.files[0]?.name,
        index
      ).then((response) => {
        setLoader(false);
        if (response === null) {
          return;
        }
        if (response.data.status_code === 1) {
          res(response.data.results);
        } else if (response.data.status_code === -1) {
          rej(response.data);
        }
      });
    }).then((response: any) => {
      schoolData.forEach((data) => {
        if (data.app_document_uuid === id) {
          setSchoolData(
            schoolData.map((item) => {
              if (item.app_document_uuid === id) {
                return {
                  ...data,
                  document_presingned_url: `${response.url}${response.fields.key}`,
                  document_url: `${response.url}${response.fields.key}`,
                  document_name: event.target?.files[0]?.name,
                  isValidFile: true,
                };
              } else {
                return item;
              }
            })
          );
        }
      });

      awsUploadFile(response.url, response.fields, event.target.files[0]).then(
        (r) => {
          setEr({
            ...er,
            document: false,
            accept_certificate: false,
          });
          setValidFields({
            ...validFields,
            isFile: true,
            isAccept: "",
          });
          setLoader(false);
        }
      );
    });
  };

  const handleInputChange = (value: string, ind: number) => {
    const state: any = [...schoolData];
    state[ind].follow_up_answer = value;
    if (value !== "") {
      const a = validationError.filter(
        (item) => item !== `followUpError${ind}`
      );
      setValidationError(a);
    }
    setSchoolData(state);
  };

  const postschoolData = async (): Promise<any> => {
    setLoader(true);
    const error_list = checkValidation();
    if (error_list.length === 0) {
      postDocument(stdAppId, schoolID, schoolData).then(() => {
        nextPage();
      });
    }
    setLoader(false);
  };

  const checkValidation = () => {
    const errorHelper: string[] = [];
    schoolData.forEach((document, index) => {
      if (document.document_name === "") {
        errorHelper.push(`docError${index}`);
      }
      if (
        document.further_detail_required &&
        document.follow_up_answer === ""
      ) {
        errorHelper.push(`followUpError${index}`);
      }
    });
    if (!checked.signature) {
      errorHelper.push("signature");
    }
    if (!checked.consent) {
      errorHelper.push("consent");
    }
    setValidationError([...errorHelper]);
    return errorHelper;
  };

  const deleteDocument = (id: string) => {
    setLoader(true);
    schoolData.forEach((data) => {
      if (data.app_document_uuid === id) {
        setSchoolData(
          schoolData.map((item) => {
            if (item.app_document_uuid === id) {
              return {
                ...data,
                document_presingned_url: "",
                document_url: "",
                document_name: "",
              };
            } else {
              return item;
            }
          })
        );
      }
    });
    setLoader(false);
  };

  const handleCheckBoxes = (type: string) => {
    const x = type === "signature" ? "signature" : "consent";
    if (!checked.signature || !checked.consent) {
      const a = validationError.filter((item) => item !== x);
      setValidationError(a);
    }

    setChecked((prevState) => ({
      ...prevState,
      [x]: !prevState[x],
    }));
  };

  return (
    <div className={styles.container}>
      <Loader open={loader} />
      <div>
        <div className={styles.bodyContainer}>
          <Grid container justifyContent="center">
            <div className={styles.card}>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <p>
                  <Icon onClick={prevPage} style={{ cursor: "pointer" }}>
                    arrow_back
                  </Icon>
                </p>
                <div>Enrollment</div>
                <ProgressBar total={6} steps={2} />
              </Box>

              <div style={{ marginBottom: 30 }}>
                <Welcome title="Documents" description="Next: Permissions" />
              </div>
              <Grid container direction="column">
                <p>
                  <b>Documents</b>
                </p>
                {schoolData.map((item: any, index: number) => (
                  <Box key={index}>
                    {item.document_name ? (
                      <DownLoad
                        id="download-document"
                        label={item.document_question}
                        file={item.document_name}
                        downloadLink={item.document_presingned_url}
                        onDelete={() => {
                          deleteDocument(item.app_document_uuid);
                        }}
                      />
                    ) : (
                      <Upload
                        id="document-question"
                        label={item.document_question}
                        placeholder="Upload Document"
                        onChange={(e: any) =>
                          change(e, item.app_document_uuid, index)
                        }
                        isFile={item.isFile}
                        file={item.document_name}
                        error={validationError.includes(`docError${index}`)}
                      />
                    )}
                    {item.follow_up_question && (
                      <Input
                        label={item.follow_up_question}
                        type="text"
                        value={item.follow_up_answer}
                        onChange={(e: any) =>
                          handleInputChange(e.target.value, index)
                        }
                        error={validationError.includes(
                          `followUpError${index}`
                        )}
                      />
                    )}
                  </Box>
                ))}
              </Grid>
              {consent ? (
                <Box>
                  <p className={styles.docConsent}>
                    {consent}
                  </p>
                </Box>
              ) : null}
              <Box>
                {signature && (
                  <Image
                    src={signature}
                    check={checked.signature}
                    change={() => {
                      handleCheckBoxes("signature");
                    }}
                    label="Your signature"
                    error={validationError.includes("signature")}
                    errorMessage="Signature confirmation is required"
                  />
                )}
              </Box>
              <Box>
                <InputDatePicker
                  label="Date signed"
                  disabled
                  value={consentDate}
                />
              </Box>
              <Box>
                <InputRadio
                  radioHolderOne="I consent"
                  check={checked.consent}
                  onChange={() => {
                    handleCheckBoxes("consent");
                  }}
                  error={validationError.includes("consent")}
                  errorMessage="Your consent is required"
                />
              </Box>
              <Grid
                container
                direction="row"
                wrap="nowrap"
                justifyContent="space-around"
              >
                <div className={styles.buttonWidth}>
                  <Button
                    label="Next"
                    light
                    endIcon="navigate_next"
                    onClick={postschoolData}
                  />
                </div>
              </Grid>
            </div>
          </Grid>
        </div>
      </div>
    </div>
  );
};

export default DocumentInfo;
