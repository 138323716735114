import Grid from "@material-ui/core/Grid";
import { Text } from "../../../components/common/text/index";
import { useState, useEffect } from "react";
import { Button } from "../../../components/common/button/index";
import { useStyle } from "../../common/register/register.style";
import { Footer } from "../../../components/common/footer/index";
import { Upload } from "../../../components/common/upload/index";
import request, { ParentUser } from "../../../utils/authUtil";
import axios from "axios";
import { Type } from "../../../utils/document-types";
import { useHistory } from "react-router-dom";
import { Loader } from "../../../components/common/loader/index";
import { Box } from "@material-ui/core";
import Icon from "@material-ui/core/Icon";
import { ViewDoc } from "../../../components/common/view-doc/index";
import { CheckNetwork, SIGNATURE_FONT } from "../../../utils/helper";
import TextSignature from "../../../components/common/text-signature/TextSignature";
import { InputRadioButton } from "../../../components/common/input-radio-button-two";

type Props = {};

const Signature = (props: Props) => {
  const classes = useStyle();
  const [loader, setLoader] = useState<boolean>(true);
  const schoolID = localStorage.getItem("school-id");
  const parentID = ParentUser?.uuid;
  const history = useHistory();
  const [parSignDoc, setParentSignDoc] = useState("");
  const [openDoc, setOpenDoc] = useState(false);
  const [validFields, setValidFields] = useState({
    fileSize: "",
    fileCheck: false,
    acceptImage: "",
  });
  const [error, setError] = useState<any>({
    file_size: false,
    parent_signature: false,
    parent_signature_accept: false,
  });

  const [locImg, setLocImage] = useState<any>({
    src: "",
    alt: "",
  });

  const [defImg, setDefImage] = useState<any>({
    src: "",
    alt: "",
  });

  const [signatureUploadType, setSignatureUploadType] = useState<string>("");
  const [showDefaultSign, setShowDefaultSign] = useState<boolean>(false);
  const [canvasName, setCanvasName] = useState<string>("");
  const handleRadioChange = (name: string) => {
    if (name === `Default`) {
      setLocImage({
        src: "",
        alt: "",
      });
      setShowDefaultSign(true);
      setSignatureUploadType("Default");
    }
    if (name === `Upload`) {
      setSignatureUploadType("Upload");
    }
  };

  useEffect(() => {
    if (ParentUser === null) {
      window.location.reload();
    }
    setLoader(false);
  }, [ParentUser]);

  const getSignedUrl = async (
    schoolId: any,
    userId: any,
    documentType: any,
    file: any,
    fileName: any
  ) => {
    setParentSignDoc(fileName);
    if (
      signatureUploadType === "Upload" &&
      (!fileName?.match(/\.(jpg|jpeg|png)$/) || locImg.src === "")
    ) {
      setValidFields({
        ...validFields,
        fileSize: "",
        fileCheck: false,
        acceptImage: "acceptError",
      });
      setError({
        ...error,
        file_size: false,
        parent_signature: false,
        parent_signature_accept: true,
      });
      setLocImage({
        src: "",
        alt: "",
      });
      setParentSignDoc("");
      return null;
    }
    // check if the file is an image, and greater than 1 MB
    // image size is usually in bytes, so convert to MB -> n bytes / 1024 x 1024
    if (
      signatureUploadType === "Upload" &&
      fileName.match(/\.(jpg|jpeg|png)$/)
      && (file?.size / 1048576 > 1)
    ) {
      setValidFields({
        ...validFields,
        fileSize: "sizeError",
        fileCheck: false,
        acceptImage: "",
      });
      setError({
        ...error,
        file_size: true,
        parent_signature: false,
        parent_signature_accept: false,
      });
      setLocImage({
        src: "",
        alt: "",
      });
      setParentSignDoc("");
      return null;
    } else {
      const res = await request({
        url: "/auth/parent-documents-upload-credentials",
        method: "post",
        data: {
          user_uuid: userId,
          school_uuid: schoolId,
          document_type: documentType,
          file_name: fileName,
        },
      });
      return res;
    }
  };

  const awsUploadFile = async (url: any, fields: any, file: any) => {
    const formData = new FormData();
    formData.append("key", fields.key);
    formData.append("policy", fields.policy);
    formData.append("x-amz-algorithm", fields["x-amz-algorithm"]);
    formData.append("x-amz-credential", fields["x-amz-credential"]);
    formData.append("x-amz-date", fields["x-amz-date"]);
    formData.append("x-amz-signature", fields["x-amz-signature"]);
    formData.append("file", file);
    const res = await axios.post(url, formData, {
      headers: { "Content-Type": "multipart/form-data" },
    });
    return res;
  };

  const onSubmitHandler = async (file: string) => {
    await request({
      url: `/parent/parent-documents-details/?uuid=${parentID}`,
      method: "put",
      data: {
        document_type: Type.signature,
        document_url: file,
      },
    }).then((res) => {
      localStorage.setItem("isSignature", "true");
      history.push("/parent/dashboard");
      window.location.reload();
    });
  };

  const change = () => {
    setLoader(true);
    new Promise((res, rej) => {
      getSignedUrl(
        schoolID,
        parentID,
        Type.signature,
        signatureUploadType === "Default" ? defImg.src : locImg.src,
        signatureUploadType === "Default" ? defImg.alt : locImg.src.name
      ).then((response) => {
        if (response === null) {
          setLoader(false);
          return;
        }
        if (response.data.status_code === 1) {
          res(response.data.results);
        } else if (response.data.status_code === -1) {
          setLoader(false);
          rej(response.data);
        }
      });
    })
      .then((response: any) => {
        let file: any;
        if (signatureUploadType === "Default") {
          let blobBin = atob(defImg.src.split(",")[1]);
          let array = [];
          for (var i = 0; i < blobBin.length; i++) {
            array.push(blobBin.charCodeAt(i));
          }
          file = new Blob([new Uint8Array(array)], { type: "image/png" });
        }
        awsUploadFile(
          response.url,
          response.fields,
          signatureUploadType === "Default" ? file : locImg.src
        )
          .then((r) => {
            setValidFields({
              ...validFields,
              fileSize: "",
              fileCheck: true,
              acceptImage: "",
            });
            setError({
              ...error,
              file_size: false,
              parent_signature: false,
              parent_signature_accept: false,
            });
            onSubmitHandler(`${response.url}${response.fields.key}`);
          })
          .catch((e) => {
            setLoader(false);
          });
      })
      .catch((error) => {
        setLoader(false);
      });
  };

  const onChangeLoc = (e: any) => {
    if (e.target.files[0]) {
      if (!e.target.files[0].name.match(/\.(jpg|jpeg|png)$/)) {
        setValidFields({
          ...validFields,
          fileSize: "",
          fileCheck: false,
          acceptImage: "acceptError",
        });
        setError({
          ...error,
          file_size: false,
          parent_signature: false,
          parent_signature_accept: true,
        });
      } else {
        setLocImage({
          src: e.target.files[0],
          alt: e.target.files[0].name,
        });
      }
    }
  };

  const getDefaultImage = (img: any) => {
    setDefImage({
      src: img,
      alt:
        ParentUser === null
          ? ""
          : `${ParentUser.user.first_name}_${ParentUser.user.last_name}.png`,
    });
  };

  useEffect(() => {
    const fullName = localStorage.getItem("fullName") as string;
    setCanvasName(fullName);
  }, []);

  return (
    <Grid
      className={classes.root}
      container
      direction="column"
      justifyContent="space-between"
    >
      <CheckNetwork />
      {loader ? null : (
        <>
          <Grid container justifyContent="center">
            <div className={classes.card}>
              <div style={{ marginBottom: 34 }}>
                <Text heading>Signature</Text>
              </div>
              <Grid container direction="column">
                <div style={{ marginBottom: 24 }}>
                  <Text subHeading>Signature capture</Text>
                </div>
                <div style={{ marginBottom: 50 }}>
                  <Text paragraph>
                    Your signature will be required at certain stages for
                    consenting to terms necessary for the completion of the
                    application.
                  </Text>
                </div>
                <InputRadioButton
                  label="Please select type of signature upload"
                  placeHolderOne="Default"
                  placeHolderTwo="Please upload your signature"
                  valueOne="Default"
                  valueTwo="Upload"
                  onChange={handleRadioChange}
                  isCheckString
                  answer={signatureUploadType}
                  index={0}
                />
                {/* this next line of code is necessary to load the font of the signature */}
                <span
                  style={{
                    height: 0,
                    fontFamily: SIGNATURE_FONT.fontFamily,
                    fontSize: SIGNATURE_FONT.fontSize,
                  }}
                >
                  .
                </span>
                {signatureUploadType === "Default" && (
                  <Box justifyContent="center" display="flex">
                    <TextSignature
                      className="text"
                      name={canvasName}
                      setImage={getDefaultImage}
                      x={0}
                      y={40}
                      height={80}
                      font={`${SIGNATURE_FONT.fontSize} ${SIGNATURE_FONT.fontFamily}`}
                      show={showDefaultSign}
                    />
                  </Box>
                )}
                {locImg.src === "" ? (
                  <>
                    {signatureUploadType === "Upload" && (
                      <Upload
                        id="regis-parent-signature"
                        label=""
                        placeholder="Upload signature"
                        onChange={(e: any) => {
                          onChangeLoc(e);
                        }}
                        isFile={validFields.fileCheck}
                        file={parSignDoc}
                        error={
                          error.parent_signature ||
                          error.parent_signature_accept
                        }
                        errorMessage={
                          validFields.acceptImage === "acceptError"
                            ? "Only image files .png .jpg .jpeg are allowed"
                            : "Is required"
                        }
                        showDeleteIcon={false}
                      />
                    )}
                    {validFields.fileSize === "sizeError" ? (
                      <div style={{ margin: "0 auto", marginBottom: "10px" }}>
                        <p className={classes.imageError}>
                          Only images with Size less than 1mb are allowed
                        </p>
                      </div>
                    ) : null}
                  </>
                ) : (
                  <>
                    <Box display="flex" justifyContent="center">
                      <Box
                        marginBottom="70px"
                        display="flex"
                        justifyContent="space-between"
                      >
                        <Box height="70px">
                          <img
                            src={URL.createObjectURL(locImg.src)}
                            alt={locImg.alt}
                            height="100%"
                          />
                        </Box>
                        <Box
                          marginTop="5px"
                          display="flex"
                          alignItems="center"
                          marginLeft="20px"
                        >
                          <Icon
                            style={{ cursor: "pointer", color: "#757575" }}
                            onClick={() =>
                              setLocImage({
                                src: "",
                                alt: "",
                              })
                            }
                          >
                            delete
                          </Icon>
                          <Icon
                            onClick={() => setOpenDoc(true)}
                            style={{
                              cursor: "pointer",
                              marginLeft: "10px",
                              color: "#757575",
                            }}
                          >
                            remove_red_eye_icon
                          </Icon>
                        </Box>
                      </Box>
                    </Box>
                    <ViewDoc
                      openDoc={openDoc}
                      docName={locImg.alt}
                      url={URL.createObjectURL(locImg.src)}
                      setOpen={setOpenDoc}
                    />
                  </>
                )}
                <Grid
                  container
                  direction="row"
                  wrap="nowrap"
                  justifyContent="space-around"
                >
                  <div className={classes.buttonWidth}>
                    <Button label="Back" light startIcon="navigate_before" />
                  </div>
                  <div className={classes.buttonWidth}>
                    <Button label="Submit" onClick={change} />
                  </div>
                </Grid>
              </Grid>
            </div>
          </Grid>
          <Grid container>
            <div style={{ width: "100vw" }}>
              <Footer center />
            </div>
          </Grid>
        </>
      )}
      <Loader open={loader} />
    </Grid>
  );
};

export default Signature;
