import { useStyle } from "./custom.style";
import { useState, useEffect } from "react";
import cn from "classnames";
import Grid from "@material-ui/core/Grid";
import { Box } from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import { CustomtextField } from "./text-field/index";
import { AdminButton } from "../../../components/common/admin-button/index";
import { Loader } from "../../../components/common/loader/index";
import {
  getCustomSettings,
  postCustomSettings,
  CustomData,
  postCustomData,
  getOptionalSettings,
  postOptionalSettings,
  postOptionalData,
} from "./cust-settings-api";
import RadioComponent from "./text-field/RadioComponent";
import { ConfirmBox } from "../../../components/common/confirm-box";
import { SelectDropDown } from "../../../components/common/select-dropdown/index";

import {
  useQuery,
  useMutation,
  UseQueryResult,
  UseMutationResult,
} from "react-query";

type Props = {
  applicationID: string;
  ready?: any;
  selectedGlobalDate?: any;
  currentGlobalDate?: any;
};

const DEFAULT = {
  // allow_acceptable_use_policy: false,
  allow_language: false,
  allow_roll_over: false,
  allow_subjects: false,
  book_grant_question: false,
  // collect_intent_to_enrol: false,
  // collect_intent_to_enrol_notice: "",
  contact_previous_school: false,
  contact_previous_school_in_application: false,
  contact_previous_school_notice: "",
  include_student_profile: false,
  // is_single_gender_school: false,
  religion_question_application: false,
  // request_irish_exemption: false,
  request_student_signature: false,
  request_utility_bill: false,
  request_utility_bill_second: false,
  school_application: "",
  // single_gender_school_notice: "",
  is_manual_lottery: false,
  is_enrol_photo_optional: false,
  is_show_wating_list_number: false,
  is_show_emergency_contact: false,
  is_show_gender_question: false,
  is_show_mother_tongue_ethnicity: false,
  mother_tongue_ethnicity_in_application: false,
  show_emergency_contact_in_application: false,
  is_show_current_primary_school_question: false,
  is_show_current_siblings: false,
  is_birth_certificate: false,
  birth_certificate_in_application: false,
  is_random_assign_wating_list_number: false,
  is_previous_school_report: false,
  previous_school_report_in_application: false,
  allow_asd_applications: false,
  school_asd_description: "",
  birth_certificate_description: "",
};

const OPTIONAL_DEFAULT = {
  is_country_of_origin: "",
  is_nationality: "",
  is_school_roll_number: "",
};

const DROPDOWN_CONTENT = [
  { name: "Hide", value: "HIDE" },
  { name: "Optional", value: "OPTIONAL" },
  { name: "Mandatory", value: "MANDATORY" },
];

const CustomSettings: React.FC<Props> = ({
  applicationID,
  ready,
  selectedGlobalDate,
  currentGlobalDate,
}) => {
  const classes = useStyle();
  const [loader, setLoader] = useState<boolean>(false);
  const [active, setActive] = useState("customFields");
  const [openConfirm, setOpenConfirm] = useState<boolean>(false);
  const [saved, setSaved] = useState<boolean>(true);
  const [nextTab, setNextTab] = useState<string>("");
  const [refresh, setRefresh] = useState(1);

  const [customSettings, setCustomSettings] = useState<
    CustomData | null | undefined
  >(DEFAULT);

  const [optionalSettings, setOptionalSettings] = useState(OPTIONAL_DEFAULT);

  const [validError, setvalidError] = useState({
    // singNotice_err: false,
    // enrollNotice_err: false,
    schoolNotice_err: false,
    asd_question_err: false,
    birth_certificate_err: false,
  });

  const {
    isLoading,
    isError,
    error: getCustomError,
    data,
    refetch,
  }: UseQueryResult<any, Error> = useQuery<any, Error>(
    ["customSettings", applicationID, selectedGlobalDate],
    () => getCustomSettings(applicationID),
    {
      select: (customSettings) => customSettings.data,
    }
  );

  const {
    isLoading: isOptionalLoading,
    isError: isOptionalError,
    error: optionalError,
    data: optionalData,
    refetch: optionalRefetch,
  }: UseQueryResult<any, Error> = useQuery<any, Error>(
    ["optionalSettings", applicationID, selectedGlobalDate],
    () => getOptionalSettings(applicationID),
    {
      select: (optionalSettings) => optionalSettings.data,
    }
  );

  const mutation: UseMutationResult<any, Error, postCustomData> = useMutation(
    async ({ id, customSettings }) =>
      postCustomSettings({ id, customSettings }),
    {
      onSuccess: (res) => {
        if (res) {
          setCustomSettings({
            ...res.data.results,
          });
        }
        readyToSwitch(true);
        setLoader(false);
      },
      onError: (res) => {
        setLoader(true);
      },
    }
  );

  const onValueChange = (e: any, inputType: string) => {
    readyToSwitch(false);
    setOptionalSettings((prev) => {
      return {
        ...prev,
        [inputType]: e.target.value,
      };
    });
  };

  const mutation_for_optional: UseMutationResult<any, Error, postOptionalData> =
    useMutation(
      async ({ id, optionalSettings }) =>
        postOptionalSettings({ id, optionalSettings }),
      {
        onSuccess: (res) => {
          if (res) {
            setOptionalSettings({
              ...res.data.results,
            });
          }
          readyToSwitch(true);
          setLoader(false);
        },
        onError: (res) => {
          setLoader(true);
        },
      }
    );

  const handleTabChange = (value: string) => {
    setNextTab(value);
    if (saved) {
      setRefresh(refresh + 1);
      refetch();
      optionalRefetch();
      setActive(value);
    } else {
      setOpenConfirm(true);
    }
  };

  const changeTab = () => {
    setRefresh(refresh - 1);
    refetch();
    optionalRefetch();
    setActive(nextTab);
    setSaved(true);
    ready(false);
  };

  const readyToSwitch = (value: boolean) => {
    setSaved(value);
    ready(value);
  };

  useEffect(() => {
    isLoading && isOptionalLoading ? setLoader(true) : setLoader(false);
  }, [isLoading, isOptionalLoading]);

  useEffect(() => {
    if (data) {
      if (Object.keys(data?.results).length !== 0) {
        setCustomSettings({
          ...customSettings,
          ...data.results,
        });
      }
    }
    if (optionalData) {
      if (Object.keys(optionalData?.results).length !== 0) {
        setOptionalSettings({
          ...optionalSettings,
          ...optionalData.results,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, optionalData, refresh]);

  const validate = () => {
    return {
      // singNotice_err:
      //   customSettings?.is_single_gender_school === true &&
      //   customSettings?.single_gender_school_notice.length === 0,
      // enrollNotice_err:
      //   customSettings?.collect_intent_to_enrol === true &&
      //   customSettings?.collect_intent_to_enrol_notice.length === 0,
      schoolNotice_err:
        customSettings?.contact_previous_school === true &&
        customSettings?.contact_previous_school_notice.length === 0,
        asd_question_err:
          customSettings?.allow_asd_applications === true &&
          customSettings?.school_asd_description.length === 0,
        birth_certificate_err:
          customSettings?.is_birth_certificate === true &&
          customSettings?.birth_certificate_description?.length === 0,
    };
  };

  const onSaveClick = () => {
    const err = validate();
    setvalidError(err);
    if (
      // err.singNotice_err === false &&
      // err.enrollNotice_err === false &&
      err.schoolNotice_err === false &&
      err.asd_question_err === false &&
      err.birth_certificate_err === false
    ) {
      setLoader(true);
      mutation.mutate({ id: applicationID, customSettings });
    }
  };

  const onOptionalSaveClick = () => {
    setLoader(true);
    mutation_for_optional.mutate({ id: applicationID, optionalSettings });
  };

  //end of error validation

  // const isSingleGend = () => {
  //   customSettings &&
  //     setCustomSettings({
  //       ...customSettings,
  //       is_single_gender_school: !customSettings.is_single_gender_school,
  //     });
  //   ready(false);
  // };

  // const isEnroll = () => {
  //   customSettings &&
  //     setCustomSettings({
  //       ...customSettings,
  //       collect_intent_to_enrol: !customSettings.collect_intent_to_enrol,
  //     });
  //   ready(false);
  // };

  const isPrevSchool = () => {
    customSettings &&
      setCustomSettings({
        ...customSettings,
        contact_previous_school: !customSettings.contact_previous_school,
      });
    ready(false);
  };

  const isAsdQuestion = () => {
    customSettings &&
      setCustomSettings({
        ...customSettings,
        allow_asd_applications: !customSettings.allow_asd_applications,
      });
    ready(false);
  };

  const isEmergencyContact = () => {
    customSettings &&
      setCustomSettings({
        ...customSettings,
        is_show_emergency_contact: !customSettings.is_show_emergency_contact,
      });
    ready(false);
  };
  const isBirthCertificate = () => {
    customSettings &&
      setCustomSettings({
        ...customSettings,
        is_birth_certificate: !customSettings.is_birth_certificate,
      });
    ready(false);
  }
  
  const isPreviousSchoolReport = () => {
    customSettings &&
      setCustomSettings({
        ...customSettings,
        is_previous_school_report: !customSettings.is_previous_school_report,
      });
    ready(false);
  }

  return (
    <Grid container direction="row" justifyContent="space-between">
      <div>
        <Loader open={loader} />
        {isError || isOptionalError ? (
          <div>
            <p>Error while Fetching data.....</p>
            <p>{isError ? getCustomError : optionalError}</p>
          </div>
        ) : null}
        <div style={{ width: "45vw" }}>
          {active === "customFields" && customSettings && (
            <div>
              <Grid item xs={12}>
                <h2 className={classes.header}>Custom Fields</h2>
              </Grid>
              <Grid item xs={12}>
                <Box display="flex" alignItems="center" justifyContent="space-between" mb={1}>
                  <Box display="flex" alignItems="center">
                  <Checkbox
                    color="default"
                    onChange={(e) => {
                      customSettings &&
                        setCustomSettings({
                          ...customSettings,
                          request_utility_bill:
                            !customSettings.request_utility_bill,
                        });
                      readyToSwitch(false);
                    }}
                    disabled={selectedGlobalDate !== currentGlobalDate}
                    checked={customSettings?.request_utility_bill}
                  />
                  <p className={classes.text}>Request Utility Bill 1</p>
                  </Box>
                  <Box title="Enrolment" className={classes.enrolmentPill}>E</Box>
                </Box>
                <Box display="flex" alignItems="center" justifyContent="space-between" mb={1}>
                  <Box display="flex" alignItems="center">
                  <Checkbox
                    color="default"
                    onChange={(e) => {
                      customSettings &&
                        setCustomSettings({
                          ...customSettings,
                          request_utility_bill_second:
                            !customSettings.request_utility_bill_second,
                        });
                      readyToSwitch(false);
                    }}
                    checked={customSettings?.request_utility_bill_second}
                    disabled={selectedGlobalDate !== currentGlobalDate}
                  />
                  <p className={classes.text}>Request Utility Bill 2</p>
                </Box>
                <Box title="Enrolment" className={classes.enrolmentPill}>E</Box>
                </Box>
                {/* <Box display="flex" alignItems="center" mb={1}>
                  <Checkbox
                    color="default"
                    disabled={selectedGlobalDate !== currentGlobalDate}
                    onChange={(e) => {
                      customSettings &&
                        setCustomSettings({
                          ...customSettings,
                          request_irish_exemption:
                            !customSettings.request_irish_exemption,
                        });
                      readyToSwitch(false);
                    }}
                    checked={customSettings?.request_irish_exemption}
                  />
                  <p className={classes.text}>
                    Request Irish exemption documentation
                  </p>
                </Box> */}
                <Box display="flex" alignItems="center" justifyContent="space-between" mb={1}>
                  <Box display="flex" alignItems="center">
                  <Checkbox
                    disabled={selectedGlobalDate !== currentGlobalDate}
                    color="default"
                    onChange={(e) => {
                      customSettings &&
                        setCustomSettings({
                          ...customSettings,
                          include_student_profile:
                            !customSettings.include_student_profile,
                        });
                      readyToSwitch(false);
                    }}
                    checked={customSettings?.include_student_profile}
                  />
                  <p className={classes.text}>Include student profile</p>
                </Box>
                <Box title="Application" className={classes.applicationPill}>A</Box>
                </Box>
                <Box display="flex" alignItems="center" justifyContent="space-between" mb={1}>
                  <Box display="flex" alignItems="center">
                  <Checkbox
                    color="default"
                    disabled={selectedGlobalDate !== currentGlobalDate}
                    onChange={(e) => {
                      customSettings &&
                        setCustomSettings({
                          ...customSettings,
                          is_show_current_siblings:
                            !customSettings.is_show_current_siblings,
                        });
                      readyToSwitch(false);
                    }}
                    checked={customSettings?.is_show_current_siblings}
                  />
                  <p className={classes.text}>
                    Request Current siblings
                  </p>
                </Box>
                <Box title="Application" className={classes.applicationPill}>A</Box>
                </Box>
                <Box display="flex" alignItems="center" mb={1}>
                  <Checkbox
                    disabled={selectedGlobalDate !== currentGlobalDate}
                    color="default"
                    onChange={(e) => {
                      customSettings &&
                        setCustomSettings({
                          ...customSettings,
                          is_show_current_primary_school_question:
                            !customSettings.is_show_current_primary_school_question,
                        });
                      readyToSwitch(false);
                    }}
                    checked={customSettings?.is_show_current_primary_school_question}
                  />
                  <p className={classes.text}>Request Current Primary School info</p>
                </Box>
                <Box display="flex" alignItems="center" justifyContent="space-between" mb={1}>
                  <Box display="flex" alignItems="center">
                  <Checkbox
                    disabled={selectedGlobalDate !== currentGlobalDate}
                    color="default"
                    onChange={(e) => {
                      customSettings &&
                        setCustomSettings({
                          ...customSettings,
                          religion_question_application:
                            !customSettings.religion_question_application,
                        });
                      readyToSwitch(false);
                    }}
                    checked={customSettings?.religion_question_application}
                  />
                  <p className={classes.text}>
                    Include Religion question on application
                  </p>
                </Box>
                <Box title="Application" className={classes.applicationPill}>A</Box>
                </Box>
                {/* <Box display="flex" alignItems="center" mb={1}>
                  <Checkbox
                    color="default"
                    onChange={() => {
                      isSingleGend();
                    }}
                    disabled={selectedGlobalDate !== currentGlobalDate}
                    checked={customSettings?.is_single_gender_school}
                  />
                  <p className={classes.text}>
                    Set single gender school notice
                  </p>
                </Box> */}
                {/* <Box style={{ maxWidth: "500px", marginLeft: "12px" }} mb={1}>
                  <CustomtextField
                    placeholder="Type Something"
                    label="Single gender school notice"
                    hide={!customSettings?.is_single_gender_school}
                    disabled={selectedGlobalDate !== currentGlobalDate}
                    onChange={(e) => {
                      customSettings &&
                        setCustomSettings({
                          ...customSettings,
                          single_gender_school_notice: e.target.value,
                        });
                      readyToSwitch(false);
                      setvalidError({
                        ...validError,
                        singNotice_err:
                          customSettings?.is_single_gender_school === true &&
                          e.target.value.length === 0,
                      });
                    }}
                    error={validError.singNotice_err}
                    value={customSettings?.single_gender_school_notice}
                  />
                </Box> */}
                {/* <Box display="flex" alignItems="center" mb={1}>
                  <Checkbox
                    color="default"
                    onClick={() => {
                      isEnroll();
                    }}
                    checked={customSettings?.collect_intent_to_enrol}
                    disabled={selectedGlobalDate !== currentGlobalDate}
                  />
                  <p className={classes.text}>
                    Collect Intention to enrol form
                  </p>
                </Box> */}
                {/* <Box style={{ maxWidth: "500px", marginLeft: "12px" }} mb={1}>
                  <CustomtextField
                    disabled={selectedGlobalDate !== currentGlobalDate}
                    placeholder="Type Something"
                    label="Intention to enrol form"
                    hide={!customSettings?.collect_intent_to_enrol}
                    onChange={(e) => {
                      customSettings &&
                        setCustomSettings({
                          ...customSettings,
                          collect_intent_to_enrol_notice: e.target.value,
                        });
                      readyToSwitch(false);
                      setvalidError({
                        ...validError,
                        enrollNotice_err:
                          customSettings?.collect_intent_to_enrol === true &&
                          e.target.value.length === 0,
                      });
                    }}
                    error={validError.enrollNotice_err}
                    value={customSettings?.collect_intent_to_enrol_notice}
                  />
                </Box> */}
                {/* <Box display="flex" alignItems="center" mb={1}>
                  <Checkbox
                    disabled={selectedGlobalDate !== currentGlobalDate}
                    color="default"
                    onChange={(e) => {
                      customSettings &&
                        setCustomSettings({
                          ...customSettings,
                          allow_acceptable_use_policy:
                            !customSettings.allow_acceptable_use_policy,
                        });
                      readyToSwitch(false);
                    }}
                    checked={customSettings?.allow_acceptable_use_policy}
                  />
                  <p className={classes.text}>Show Acceptable Use Policy</p>
                </Box> */}
                <Box display="flex" alignItems="center" justifyContent="space-between" mb={1}>
                  <Box display="flex" alignItems="center">
                  <Checkbox
                    disabled={selectedGlobalDate !== currentGlobalDate}
                    color="default"
                    onChange={() => {
                      isPrevSchool();
                    }}
                    checked={customSettings?.contact_previous_school}
                  />
                  <p className={classes.text}>Contact previous school</p>
                </Box>
                {
                customSettings?.contact_previous_school ?
                customSettings?.contact_previous_school_in_application ?
                  <Box title="Application" className={classes.applicationPill}>A</Box>
                    :
                  <Box title="Enrolment" className={classes.enrolmentPill}>E</Box>
                  :
                  null
                }
                </Box>

                <Box style={{ maxWidth: "500px", marginLeft: "12px" }} mb={1}>
                  <CustomtextField
                    disabled={selectedGlobalDate !== currentGlobalDate}
                    placeholder="Type Something"
                    label="Previous school notice"
                    hide={!customSettings?.contact_previous_school}
                    onChange={(e) => {
                      customSettings &&
                        setCustomSettings({
                          ...customSettings,
                          contact_previous_school_notice: e.target.value,
                        });
                      readyToSwitch(false);
                      setvalidError({
                        ...validError,
                        schoolNotice_err:
                          customSettings?.contact_previous_school === true &&
                          e.target.value.length === 0,
                      });
                    }}
                    error={validError.schoolNotice_err}
                    value={customSettings?.contact_previous_school_notice}
                    radioComponent={
                      <RadioComponent
                        value={customSettings}
                        setValue={setCustomSettings}
                        field="contact_previous_school_in_application"
                      />
                    }
                  />
                </Box>
                <Box display="flex" alignItems="center" justifyContent="space-between" mb={1}>
                  <Box display="flex" alignItems="center">
                  <Checkbox
                    disabled={selectedGlobalDate !== currentGlobalDate}
                    color="default"
                    onChange={() => {
                      isEmergencyContact();
                    }}
                    checked={customSettings?.is_show_emergency_contact}
                  />
                  <p className={classes.text}>Show Emergency Contact</p>
                </Box>
                {
                customSettings?.is_show_emergency_contact ?
                customSettings?.show_emergency_contact_in_application ?

                  <Box title="Application" className={classes.applicationPill}>A</Box>
                    :
                  <Box title="Enrolment" className={classes.enrolmentPill}>E</Box>
                  :
                  null
                }
                </Box>

                {customSettings?.is_show_emergency_contact && (
                  <Box style={{ maxWidth: "500px", marginLeft: "12px" }} mb={1}>
                    <RadioComponent
                      value={customSettings}
                      setValue={setCustomSettings}
                      field="show_emergency_contact_in_application"
                    />
                  </Box>
                  
                )}
                <Box display="flex" alignItems="center" mb={1}>
                  <Checkbox
                    disabled={selectedGlobalDate !== currentGlobalDate}
                    color="default"
                    onChange={(e) => {
                      customSettings &&
                        setCustomSettings({
                          ...customSettings,
                          is_manual_lottery: !customSettings.is_manual_lottery,
                        });
                      readyToSwitch(false);
                    }}
                    checked={customSettings?.is_manual_lottery}
                  />
                  <p className={classes.text}>Use Manual Lottery Process</p>
                </Box>
                <Box display="flex" alignItems="center" mb={1}>
                  <Checkbox
                    disabled={selectedGlobalDate !== currentGlobalDate}
                    color="default"
                    onChange={(e) => {
                      customSettings &&
                        setCustomSettings({
                          ...customSettings,
                          is_show_wating_list_number:
                            !customSettings.is_show_wating_list_number,
                        });
                      readyToSwitch(false);
                    }}
                    checked={customSettings?.is_show_wating_list_number}
                  />
                  <p className={classes.text}>Show Waiting List Number</p>
                </Box>
                <Box display="flex" alignItems="center" justifyContent="space-between" mb={1}>
                  <Box display="flex" alignItems="center">
                  <Checkbox
                    disabled={selectedGlobalDate !== currentGlobalDate}
                    color="default"
                    onChange={(e) => {
                      customSettings &&
                        setCustomSettings({
                          ...customSettings,
                          is_show_gender_question:
                            !customSettings.is_show_gender_question,
                        });
                      readyToSwitch(false);
                    }}
                    checked={customSettings?.is_show_gender_question}
                  />
                  <p className={classes.text}>Show Gender Question</p>
                </Box>
                  <Box title="Application" className={classes.applicationPill}>A</Box>
                </Box>
                <Box display="flex" alignItems="center" justifyContent="space-between" mb={1}>
                  <Box display="flex" alignItems="center">
                  <Checkbox
                    disabled={selectedGlobalDate !== currentGlobalDate}
                    color="default"
                    onChange={(e) => {
                      customSettings &&
                        setCustomSettings({
                          ...customSettings,
                          is_show_mother_tongue_ethnicity:
                            !customSettings.is_show_mother_tongue_ethnicity,
                        });
                      readyToSwitch(false);
                    }}
                    checked={customSettings?.is_show_mother_tongue_ethnicity}
                  />
                  <p className={classes.text}>Show Mother Tongue, ethnicity and cultural background  Question</p>
                </Box>
                {
                customSettings?.is_show_mother_tongue_ethnicity ?
                customSettings?.mother_tongue_ethnicity_in_application ?

                  <Box title="Application" className={classes.applicationPill}>A</Box>
                    :
                  <Box title="Enrolment" className={classes.enrolmentPill}>E</Box>
                  :
                  null
                }
                </Box>
                {customSettings?.is_show_mother_tongue_ethnicity ? <Box style={{ maxWidth: "500px", marginLeft: "12px" }} mb={1}>
                  <RadioComponent
                    value={customSettings}
                    setValue={setCustomSettings}
                    field="mother_tongue_ethnicity_in_application"
                  />
                </Box> : null}

                <Box display="flex" alignItems="center" justifyContent="space-between" mb={1}>
                  <Box display="flex" alignItems="center">
                  <Checkbox
                    disabled={selectedGlobalDate !== currentGlobalDate}
                    color="default"
                    onChange={isBirthCertificate}
                    checked={customSettings?.is_birth_certificate}
                  />
                  <p className={classes.text}>Show Upload Birth certificate</p>
                </Box>
                {
                customSettings?.is_birth_certificate ?
                customSettings?.birth_certificate_in_application ?
                  <Box title="Application" className={classes.applicationPill}>A</Box>
                    :
                  <Box title="Enrolment" className={classes.enrolmentPill}>E</Box>
                  :
                  null
                }
                </Box>

                <Box style={{ maxWidth: "500px", marginLeft: "12px" }} mb={1}>
                  <CustomtextField
                    disabled={selectedGlobalDate !== currentGlobalDate}
                    placeholder="Type Something"
                    label="Birth certificate notice"
                    hide={!customSettings?.is_birth_certificate}
                    onChange={(e) => {
                      customSettings &&
                        setCustomSettings({
                          ...customSettings,
                          birth_certificate_description: e.target.value,
                        });
                      readyToSwitch(false);
                      setvalidError({
                        ...validError,
                        birth_certificate_err:
                          customSettings?.is_birth_certificate === true &&
                          e.target.value.length === 0,
                      });
                    }}
                    error={validError.birth_certificate_err}
                    value={customSettings?.birth_certificate_description}
                    radioComponent={
                      <RadioComponent
                        value={customSettings}
                        setValue={setCustomSettings}
                        field="birth_certificate_in_application"
                      />
                    }
                  />
                </Box>
                <Box display="flex" alignItems="center" mb={1}>
                  <Checkbox
                    disabled={selectedGlobalDate !== currentGlobalDate}
                    color="default"
                    onChange={(e) => {
                      customSettings &&
                        setCustomSettings({
                          ...customSettings,
                          is_random_assign_wating_list_number: !customSettings.is_random_assign_wating_list_number,
                        });
                      readyToSwitch(false);
                    }}
                    checked={customSettings?.is_random_assign_wating_list_number}
                  />
                  <p className={classes.text}>Use Random Assignment for waiting list number</p>
                </Box>
                <Box display="flex" alignItems="center" justifyContent="space-between" mb={1}>
                  <Box display="flex" alignItems="center">
                  <Checkbox
                    color="default"
                    disabled={selectedGlobalDate !== currentGlobalDate}
                    onChange={(e) => {
                      customSettings &&
                        setCustomSettings({
                          ...customSettings,
                          is_enrol_photo_optional:
                            !customSettings.is_enrol_photo_optional,
                        });
                      readyToSwitch(false);
                    }}
                    checked={customSettings?.is_enrol_photo_optional}
                  />
                  <p className={classes.text}>Request photo of Student</p>
                </Box>
                {
                customSettings?.is_previous_school_report ?
                customSettings?.previous_school_report_in_application ?

                  <Box title="Application" className={classes.applicationPill}>A</Box>
                    :
                  <Box title="Enrolment" className={classes.enrolmentPill}>E</Box>
                  :
                  null
                }
                </Box>
                
                <Box display="flex" alignItems="center" justifyContent="space-between" mb={1}>
                  <Box display="flex" alignItems="center">
                  <Checkbox
                    disabled={selectedGlobalDate !== currentGlobalDate}
                    color="default"
                    onChange={isPreviousSchoolReport}
                    checked={customSettings?.is_previous_school_report}
                  />
                  <p className={classes.text}>Show previous school report</p>
                </Box>
                {
                customSettings?.is_previous_school_report ?
                customSettings?.previous_school_report_in_application ?

                  <Box title="Application" className={classes.applicationPill}>A</Box>
                    :
                  <Box title="Enrolment" className={classes.enrolmentPill}>E</Box>
                  :
                  null
                }
                </Box>
                {customSettings?.is_previous_school_report ? <Box style={{ maxWidth: "500px", marginLeft: "12px" }} mb={1}>
                  <RadioComponent
                    value={customSettings}
                    setValue={setCustomSettings}
                    field="previous_school_report_in_application"
                  />
                </Box> : null}
                <Box display="flex" alignItems="center" justifyContent="space-between" mb={1}>
                  <Box display="flex" alignItems="center">
                  <Checkbox
                    disabled={selectedGlobalDate !== currentGlobalDate}
                    color="default"
                    onChange={() => {
                      isAsdQuestion();
                    }}
                    checked={customSettings?.allow_asd_applications}
                  />
                  <p className={classes.text}>Show ASD question in application section</p>
                </Box>
                </Box>

                <Box style={{ maxWidth: "500px", marginLeft: "12px" }} mb={1}>
                  <CustomtextField
                    disabled={selectedGlobalDate !== currentGlobalDate}
                    placeholder="Type Something"
                    label="ASD section notice"
                    hide={!customSettings?.allow_asd_applications}
                    onChange={(e) => {
                      customSettings &&
                        setCustomSettings({
                          ...customSettings,
                          school_asd_description: e.target.value,
                        });
                      readyToSwitch(false);
                      setvalidError({
                        ...validError,
                        asd_question_err:
                          customSettings?.allow_asd_applications === true &&
                          e.target.value.length === 0,
                      });
                    }}
                    error={validError.asd_question_err}
                    value={customSettings?.school_asd_description}
                  />
                </Box>
                {selectedGlobalDate === currentGlobalDate && (
                  <div
                    style={{
                      maxWidth: "30%",
                      marginBottom: "60px",
                      marginLeft: "15px",
                    }}
                  >
                    <AdminButton label="Save" onClick={onSaveClick} />
                  </div>
                )}
              </Grid>
            </div>
          )}
          {active === "optionals" && (
            <Grid container>
              <Grid item xs={12}>
                <h2 className={classes.header}>Optionals</h2>
              </Grid>
              <Grid item xs={12} sm={6}>
                <div>
                  <SelectDropDown
                    label="Request for Country of Origin"
                    item={DROPDOWN_CONTENT}
                    value={optionalSettings?.is_country_of_origin}
                    onChange={(e: any) => {
                      onValueChange(e, "is_country_of_origin");
                    }}
                  />
                </div>
                <div>
                  <SelectDropDown
                    label="Request for Nationality"
                    item={DROPDOWN_CONTENT}
                    value={optionalSettings?.is_nationality}
                    onChange={(e: any) => {
                      onValueChange(e, "is_nationality");
                    }}
                  />
                </div>
                <div>
                  <SelectDropDown
                    label="Request for School Roll Number"
                    item={DROPDOWN_CONTENT}
                    value={optionalSettings?.is_school_roll_number}
                    onChange={(e: any) => {
                      onValueChange(e, "is_school_roll_number");
                    }}
                  />
                </div>
                {selectedGlobalDate === currentGlobalDate && (
                  <div
                    style={{
                      maxWidth: "50%",
                      marginBottom: "60px",
                      marginLeft: "15px",
                    }}
                  >
                    <AdminButton label="Save" onClick={onOptionalSaveClick} />
                  </div>
                )}
              </Grid>
            </Grid>
          )}
        </div>
      </div>
      <div>
        <div className={classes.tab}>
          <button
            className={cn(
              classes.tabButton,
              active === "customFields" ? classes.tabActive : null
            )}
            onClick={() => handleTabChange("customFields")}
          >
            Custom Fields
          </button>
          <button
            className={cn(
              classes.tabButton,
              active === "optionals" ? classes.tabActive : null
            )}
            onClick={() => handleTabChange("optionals")}
          >
            Optionals
          </button>
        </div>
        {active === "customFields" && customSettings && 
        (
        <div className={classes.legends}>
          <h3>Legends</h3>
          <div><span className={classes.enrolmentPill}>E</span> : Enrolment</div>
          <br/>
          <div><span className={classes.applicationPill}>A</span> : Application</div>
        </div>
        )}
      </div>
      <ConfirmBox
        message={`You have unsaved data. Do you want to continue without saving?`}
        onClick={changeTab}
        open={openConfirm}
        setOpen={setOpenConfirm}
      />
    </Grid>
  );
};

export default CustomSettings;
