/* eslint-disable array-callback-return */
import { useState, useEffect } from "react";
import { Welcome } from "../../../../components/common/welcome/index";
import { Button } from "../../../../components/common/button/index";
import { Input } from "../../../../components/common/input/index";
import { useStyle } from "./school-info.style";
import Grid from "@material-ui/core/Grid";
import Icon from "@material-ui/core/Icon";
import { InputRadioButton } from "../../../../components/common/input-radio-button-two/index";
import { Box } from "@material-ui/core";
import { Loader } from "../../../../components/common/loader/index";
import { ProgressBar } from "../../../../components/common/progress-bar/index";
import {
  useQuery,
  useMutation,
  UseQueryResult,
  UseMutationResult,
} from "react-query";
import {
  getStudentAppCriteria,
  postCriteriaInfo,
  getInstruction,
} from "./school-info.api";
import { SelectDropDown } from "../../../../components/common/select-dropdown/index";
import request from "../../../../utils/authUtil";
import { ViewDocParent } from "../../../../components/common/View-doc-parent";

type Props = {
  nextPage?: any;
  prevPage?: any;
  stdAppId: string;
};

interface schoolInfoData {
  criteria_uuid: string;
  question: string;
  further_detail_required: boolean;
  further_question_type: string;
  follow_up_question: string;
  answer: boolean;
  followup_answer: string;
  further_answer: string;
  details_list: string[] | [];
}

interface postInfoData {
  schoolInfo: schoolInfoData[] | [];
}

const SchoolInfo = (props: Props) => {
  const styles = useStyle();
  const { nextPage, prevPage, stdAppId } = props;
  const [loader, setLoader] = useState<boolean>(false);
  const schoolID = localStorage.getItem("school-id");
  const [schoolInfo, setSchoolInfo] = useState<schoolInfoData[] | []>([]);
  const [criteriaInstruction, setcriteriaInstruction] = useState<string>("");
  const [error, setError] = useState<string[]>([]);
  const [invalidFlag, setInvalidFlag] = useState<boolean>(false);
  const [catchArea, setCatchArea] = useState<any>("");
  const [showCatch, setShowCatch] = useState<boolean>(false);
  const [openDoc, setOpenDoc] = useState(false);

  const handleRadioChange = (name: boolean, index: number) => {
    const tempErr = [...error];
    const val = tempErr.indexOf(`empty${index}`);
    if (val > -1) {
      tempErr.splice(val, 1);
    }
    setError(tempErr);

    setSchoolInfo((schoolInfoArray: schoolInfoData[]) => {
      return schoolInfoArray.map(
        (schoolInfoItem: schoolInfoData, idx: number) => {
          if (idx === index) {
            return {
              ...schoolInfoItem,
              answer: name,
            };
          }
          return schoolInfoItem;
        }
      );
    });
  };

  // post school info//
  const postSchoolInfo = async ({ schoolInfo }: postInfoData): Promise<any> => {
    const data = schoolInfo.map((item: any) => {
      return {
        criteria_uuid: item.criteria_uuid,
        answer: item.answer,
        followup_answer: item.followup_answer,
        further_answer: item.further_answer,
      };
    });
    postCriteriaInfo(stdAppId, schoolID, data);
  };

  const {
    isLoading,
    isError,
    isSuccess,
    error: getSchoolError,
    data,
  }: UseQueryResult<any, Error> = useQuery<any, Error>(
    ["schoolinfo"],
    () => getStudentAppCriteria(stdAppId, schoolID),
    {
      select: (schoolInfo) => schoolInfo.data,
    }
  );

  useEffect(() => {
    isLoading ? setLoader(true) : setLoader(false);
  }, [isLoading]);

  useEffect(() => {
    if (data) {
      getInstruction(schoolID).then((res) =>
        setcriteriaInstruction(res.data.results.instruction)
      );
      if (Object.keys(data?.results).length !== 0) {
        setSchoolInfo(data.results);
      }
    }
  }, [data]);

  const mutation: UseMutationResult<any, Error, postInfoData> = useMutation(
    async ({ schoolInfo }) => postSchoolInfo({ schoolInfo }),
    {
      onSuccess: (res) => {
        setLoader(false);
        nextPage();
      },
      onError: (res) => {
        setLoader(false);
      },
    }
  );

  const onSaveClick = () => {
    const temp = [...error];
    let valid = false;
    try {
      schoolInfo.map((item: any, index: any) => {
        if (item.answer === "") {
          if (!temp.includes(`empty${index}`)) {
            temp.push(`empty${index}`);
          }
        } else if (temp.includes(`empty${index}`)) {
          const val = temp.indexOf(`empty${index}`);
          if (index > -1) {
            temp.splice(val, 1);
          }
        }
        if (
          item.answer === true &&
          item.further_detail_required === true &&
          item.followup_answer === ""
        ) {
          if (!temp.includes(`follow${index}`)) {
            temp.push(`follow${index}`);
          }
        } else if (temp.includes(`follow${index}`)) {
          const val = temp.indexOf(`follow${index}`);
          if (index > -1) {
            temp.splice(val, 1);
          }
        }
        if (
          item.answer === true &&
          item.further_question_required === true &&
          item.further_answer === ""
        ) {
          if (!temp.includes(`further${index}`)) {
            temp.push(`further${index}`);
          }
        } else if (temp.includes(`further${index}`)) {
          const val = temp.indexOf(`further${index}`);
          if (index > -1) {
            temp.splice(val, 1);
          }
        }
        if (valid === false && item.answer) {
          valid = true;
        }
      });
      if (temp.length !== 0) {
        throw temp;
      } else {
        setError([]);
        setLoader(true);
        if (valid) {
          mutation.mutate({
            schoolInfo,
          });
        } else {
          setInvalidFlag(true);
          setLoader(false);
        }
      }
    } catch (temp: any) {
      setError(temp);
    }
  };

  const onChange = (item: any, value: any, ind: any) => {
    const xyz = [...schoolInfo];
    const temp = [...error];
    xyz[ind].followup_answer = value;
    setSchoolInfo(xyz);
    if (
      item.answer === true &&
      item.further_detail_required === true &&
      item.followup_answer.length === 0
    ) {
      if (!temp.includes(`follow${ind}`)) {
        temp.push(`follow${ind}`);
      }
    } else if (temp.includes(`follow${ind}`)) {
      const val = temp.indexOf(`follow${ind}`);
      if (ind > -1) {
        temp.splice(val, 1);
      }
    }
    setError(temp);
  };

  const onSecondFurtherQuestionChange = (item: any, value: any, ind: any) => {
    const xyz = [...schoolInfo];
    const temp = [...error];
    xyz[ind].further_answer = value;
    setSchoolInfo(xyz);
    if (
      item.answer === true &&
      item.further_question_required === true &&
      item.further_answer.length === 0
    ) {
      if (!temp.includes(`further${ind}`)) {
        temp.push(`further${ind}`);
      }
    } else if (temp.includes(`further${ind}`)) {
      const val = temp.indexOf(`further${ind}`);
      if (ind > -1) {
        temp.splice(val, 1);
      }
    }
    setError(temp);
  };

  useEffect(() => {
    setLoader(true);
    request({
      url: `school/school-catchment-area-details/?school_uuid=${schoolID}`,
      method: "get",
    })
      .then((response) => {
        if (response.data) {
          setLoader(false);
          if (Object.keys(response.data.results).length !== 0) {
            setCatchArea(response.data.results);
          }
        } else {
          setLoader(false);
        }
      })
      .catch();
  }, []);

  useEffect(() => {
    request({
      url: `student-application/student-application-custom-settings/?school_uuid=${schoolID}`,
      method: "get",
    }).then((res) => {
      setShowCatch(res?.data?.results.is_show_catchment_area_in_application);
    });
  }, []);

  return (
    <div className={styles.container}>
      <Loader open={loader} />
      {isError ? (
        <div>
          <p>Error while Fetching data.....</p>
          <p>{getSchoolError}</p>
        </div>
      ) : null}
      {isSuccess ? (
        <div>
          <div className={styles.bodyContainer}>
            <Grid container justifyContent="center">
              <div className={styles.card}>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <div>
                    <p>
                      <Icon onClick={prevPage} style={{ cursor: "pointer" }}>
                        arrow_back
                      </Icon>
                    </p>
                    <div>Application</div>
                  </div>
                  <ProgressBar total={6} steps={5} />
                </Box>
                <div
                  style={{
                    marginBottom: 30,
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Welcome
                    title="School Info"
                    description="Next: Student/Child Profile"
                  />
                  {showCatch && (
                    <div style={{ marginTop: 50 }}>
                      <b
                        className={styles.catchButton}
                        onClick={() => setOpenDoc(true)}
                      >
                        View Catchment Area
                      </b>
                      <ViewDocParent
                        openDoc={openDoc}
                        setOpen={setOpenDoc}
                        docName={catchArea.file_name}
                        url={catchArea.image_presigned_url}
                        imgText={catchArea.image_text}
                      />
                    </div>
                  )}
                </div>
                <Grid container direction="column">
                  <p>{criteriaInstruction}</p>
                  <p>
                    <b>Selection Criteria</b>
                  </p>
                  {schoolInfo.map((item: any, index: number) => (
                    <Box key={index}>
                      <InputRadioButton
                        label={item.question}
                        placeHolderOne="No"
                        placeHolderTwo="Yes"
                        valueOne={false}
                        valueTwo={true}
                        answer={item.answer}
                        onChange={handleRadioChange}
                        index={index}
                        error={error.includes(`empty${index}`)}
                      />
                      {item.answer && item.further_detail_required ? (
                        <div>
                          <Box>
                            {item.further_question_type === "text" && (
                              <Input
                                label={item.follow_up_question}
                                onChange={(e: { target: { value: any } }) =>
                                  onChange(item, e.target.value, index)
                                }
                                value={item.followup_answer}
                                error={error.includes(`follow${index}`)}
                              />
                            )}
                            {item.further_question_type === "drop" && (
                              <Drop
                                item={item}
                                onChange={onChange}
                                index={index}
                                error={error}
                                values={item.details_list}
                                label={item.follow_up_question}
                              />
                            )}
                          </Box>
                          {item?.further_question_required &&
                          item?.followup_answer ? (
                            <Box>
                              <Input
                                label={item.further_question}
                                onChange={(e: { target: { value: any } }) =>
                                  onSecondFurtherQuestionChange(
                                    item,
                                    e.target.value,
                                    index
                                  )
                                }
                                value={item.further_answer}
                                error={error.includes(`further${index}`)}
                              />
                            </Box>
                          ) : null}
                        </div>
                      ) : null}
                    </Box>
                  ))}
                  {invalidFlag && (
                    <p className={styles.invalid}>
                      * Atleast one Criteria must be marked as Yes
                    </p>
                  )}
                  <Grid
                    container
                    direction="row"
                    wrap="nowrap"
                    justifyContent="space-around"
                  >
                    <div className={styles.buttonWidth}>
                      <Button
                        label="Next"
                        light
                        endIcon="navigate_next"
                        onClick={onSaveClick}
                      />
                    </div>
                  </Grid>
                </Grid>
              </div>
            </Grid>
          </div>
        </div>
      ) : null}
    </div>
  );
};

type DropProps = {
  item: any;
  onChange: any;
  index: any;
  error: any;
  values: any;
  label: string;
};
const Drop: React.FC<DropProps> = ({
  item,
  onChange,
  index,
  error,
  values,
  label,
}) => {
  let options = [{ name: "--Select--", value: "" }];
  options = [
    ...options,
    ...values.map((option: string) => ({
      name: option,
      value: option,
    })),
  ];

  return (
    <SelectDropDown
      label={label}
      item={options}
      value={item.followup_answer}
      onChange={(e: any) => {
        onChange(item, e.target.value, index);
      }}
      error={error.includes(`follow${index}`)}
    />
  );
};
export default SchoolInfo;
