import React, { useState } from "react";
import { pdfjs } from "react-pdf";
import Icon from "@material-ui/core/Icon";
import { Box } from "@material-ui/core";
import { useStyle } from "./view-doc.style";
import Dialog from "@material-ui/core/Dialog";
import { ImageLoader } from "../image-loader/index";

type DocProps = {
  url?: any;
  docName: string;
  openDoc: boolean;
  setOpen: any;
  imgText: string;
};

export const ViewDocParent: React.FC<DocProps> = ({
  url,
  docName,
  openDoc,
  setOpen,
  imgText,
}) => {
  const classes = useStyle();
  const [imgLoad, setImgLoad] = useState(true);

  const handleClose = () => {
    setOpen(false);
    setImgLoad(true);
  };

  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

  return (
    <>
      {openDoc && docName.match(/\.(jpg|jpeg|png)$/) ? (
        <Dialog open={openDoc} maxWidth="xl" fullScreen>
          <Box width="1200px" height="600px">
            <Icon
              onClick={handleClose}
              style={{
                padding: "10px",
                cursor: "pointer",
              }}
            >
              close
            </Icon>
            <div style={{ textAlign: "right", margin: "20px 20px 0 0" }}></div>
            <div className={classes.imageContainer}>
              {imgLoad ? <ImageLoader /> : null}
              <div style={{ display: imgLoad ? "none" : "block" }}>
                <img
                  src={url}
                  alt="loading.."
                  className={classes.image}
                  onLoad={() => {
                    setImgLoad(false);
                  }}
                />
                <div style={{ marginTop: "30px", textAlign: "center" }}>
                  <span>{imgText}</span>
                </div>
              </div>
            </div>
          </Box>
        </Dialog>
      ) : null}
    </>
  );
};
