import {useState, useEffect} from 'react'
import { Box } from '@material-ui/core';
import request from "../../../../../../utils/authUtil"
import { Type } from "../../../../../../utils/document-types";
import {
  getASDUploadDocuments,
} from "../../../../../parent/application/asd-confirmation-page/asd-confirmation-page.api";
import { DownLoad } from '../../../../../../components/common/download';
import { Upload } from '../../../../../../components/common/upload';
import { remove } from "lodash";
import axios from 'axios';
import { CustomtextField } from '../../../../custom-settings/text-field';

type Props = {
  applicationID?: any,
  formDisable?: boolean,
  setFormDisable?: any,
  errorEdit?: string,
  setErrorEdit?: any
}

interface schoolDocument {
  asd_document_uuid: string;
  asd_document_name: string;
  asd_document_presingned_url: string;
  asd_document_question: string;
  app_asd_document_url: string;
  asd_follow_up_answer: string;
  asd_follow_up_question: string;
  further_detail_required: boolean;
  isUploaded: boolean;
}

const ASDDocuments = (props: Props) => {
  const {applicationID, formDisable, setFormDisable, errorEdit, setErrorEdit} = props;
  let parentID = localStorage.getItem("parent-id");
  const schoolID = localStorage.getItem("school-id");
  const [documentData, setDocumentData] = useState<schoolDocument[]>([
    {
      asd_document_uuid: "",
      asd_document_name: "",
      asd_document_presingned_url: "",
      asd_document_question: "",
      app_asd_document_url: "",
      asd_follow_up_answer: "",
      asd_follow_up_question: "",
      further_detail_required: false,
      isUploaded: false,
    },
  ]);
  const [validationError, setValidationError] = useState<string[]>([]);

  const [isFile, setIsFile] = useState<string[]>([""]);

  const [er, setEr] = useState<any>({
    document: false,
    accept_certificate: false,
    index: "",
  });

  const [downError, setDownError] = useState<string[]>([]);

  useEffect(() => {
    const fn = async()=>{
    let response = await getASDUploadDocuments(applicationID, schoolID)
    if (response?.data?.results?.length > 0) {
      const refinedData = response?.data?.results.map((docItem: any) => {
        if (docItem?.asd_document_name && docItem?.app_asd_document_url) {
          return {
            ...docItem,
            isUploaded: true,
          };
        } else {
          return {
            ...docItem,
            isUploaded: false,
          };
        }
      });
      setDocumentData(refinedData);
    }
    }
    fn();
  }, [applicationID, schoolID]);


  const awsUploadFile = async (url: any, fields: any, file: any) => {
    const formData = new FormData();
    formData.append("key", fields.key);
    formData.append("policy", fields.policy);
    formData.append("x-amz-algorithm", fields["x-amz-algorithm"]);
    formData.append("x-amz-credential", fields["x-amz-credential"]);
    formData.append("x-amz-date", fields["x-amz-date"]);
    formData.append("x-amz-signature", fields["x-amz-signature"]);
    formData.append("file", file);
    const res = await axios.post(url, formData, {
      headers: { "Content-Type": "multipart/form-data" },
    });
    return res;
  };

  const getSignedUrl = async (
    schoolId: any,
    userId: any,
    documentType: any,
    fileName: any,
    index: any,
    id: string
  ) => {
    if (fileName) {
      const temp = [...downError];
      if (!fileName.match(/\.(jpg|jpeg|png|pdf|PDF|JPG|JPEG|PNG)$/)) {
        if (!temp.includes(`${index}`)) {
          temp.push(`${index}`);
        }
        setDownError(temp);

        const tmp = isFile;
        remove(tmp, (n) => n === id);
        setIsFile(tmp);
        setEr({
          ...er,
          document: false,
          accept_certificate: true,
          index: `${index}`,
        });
        return null;
      } else {
        if (temp.includes(`${index}`)) {
          const val = temp.indexOf(`${index}`);
          if (index > -1) {
            temp.splice(val, 1);
          }
        }
        setDownError(temp);
        const res = await request({
          url: "/auth/parent-documents-upload-credentials",
          method: "post",
          data: {
            user_uuid: userId,
            school_uuid: schoolId,
            document_type: documentType,
            file_name: fileName,
          },
        });
        return res;
      }
    } else {
      return null;
    }
  };

  
  const deleteDocument = (id: string) => {
    let tmp = documentData.map((x) => {
      if (x.asd_document_uuid === id) {
        return {
          ...x,
          asd_document_name: "",
          asd_document_presingned_url: "",
          app_asd_document_url: "",
          isUploaded: false,
        };
      } else {
        return x;
      }
    });
    const x = isFile;
    remove(x, (n) => n === id);
    setIsFile(x);
    setDocumentData(tmp);
  };

  const handleInputChange = (value: string, ind: number) => {
    const state: any = [...documentData];
    state[ind].asd_follow_up_answer = value;
    if (value !== "") {
      const a = validationError.filter(
        (item) => item !== `followUpError${ind}`
      );
      setValidationError(a);
    }
    setDocumentData(state);
  };

  const change = (event: any, id: string, index: any) => {
    new Promise((res, rej) => {
      getSignedUrl(
        schoolID,
        parentID,
        Type.other,
        event.target?.files[0]?.name,
        index,
        id
      ).then((response) => {
        if (response === null) {
          return;
        }
        if (response.data.status_code === 1) {
          res(response.data.results);
        } else if (response.data.status_code === -1) {
          rej(response.data);
        }
      });
    }).then((response: any) => {
      documentData.forEach((data) => {
        if (data.asd_document_uuid === id) {
          setDocumentData(
            documentData.map((item) => {
              if (item.asd_document_uuid === id) {
                return {
                  ...data,
                  asd_document_presingned_url: `${response.url}${response.fields.key}`,
                  app_asd_document_url: `${response.url}${response.fields.key}`,
                  asd_document_name: event.target?.files[0]?.name,
                  isValidFile: true,
                };
              } else {
                return item;
              }
            })
          );
        }
      });

      awsUploadFile(response.url, response.fields, event.target.files[0]).then(
        (r) => {
          setEr({
            ...er,
            document: false,
            accept_certificate: false,
          });
          setIsFile([...isFile, id]);
          const a = validationError.filter(
            (item) => item !== `docError${index}`
          );
          setValidationError(a);
        }
      );
    });
  };
  
  return (
    <div>
      { documentData?.length > 0 ?
          documentData?.map((item: any, index: number) => (
            <Box key={index}>
              {item.isUploaded ? (
                <DownLoad
                  id={`download-document${item.asd_document_uuid}`}
                  label={item.asd_document_question}
                  file={item.asd_document_name}
                  downloadLink={item.asd_document_presingned_url}
                  viewOnly={formDisable}
                  onDelete={() => {
                    deleteDocument(item.asd_document_uuid);
                  }}
                />
              ) : (
                <Upload
                disabled={formDisable}
                  id={`document-question${item.asd_document_uuid}`}
                  label={item.asd_document_question}
                  placeholder="Upload Document"
                  onChange={(e: any) =>
                    change(e, item.asd_document_uuid, index)
                  }
                  isFile={isFile?.includes(item.asd_document_uuid)}
                  file={item.asd_document_name}
                  error={validationError.includes(`docError${index}`)}
                  onDelete={() => deleteDocument(item.asd_document_uuid)}
                />
              )}
              {item.further_detail_required &&
                item.asd_follow_up_question && (
                  <CustomtextField
                  disabled={formDisable}
                  label={item.asd_follow_up_question}
                  hide={!item.further_detail_required &&
                    !item.asd_follow_up_question}
                  value={item.asd_follow_up_answer}
                  onChange={(e: any) =>
                        handleInputChange(e.target.value, index)
                      }
                  error={validationError.includes(`followUpError${index}`)}
                  />
                )}
            </Box>
          )): null}
    </div>
  )
}

export default ASDDocuments