import request from "../../../utils/authUtil";
export interface CustomData {
  // allow_acceptable_use_policy: boolean
  allow_language: boolean
  allow_roll_over: boolean
  allow_subjects: boolean
  book_grant_question: boolean
  // collect_intent_to_enrol: boolean
  // collect_intent_to_enrol_notice: string
  contact_previous_school: boolean
  contact_previous_school_in_application: boolean
  contact_previous_school_notice: string
  include_student_profile: boolean
  // is_single_gender_school: boolean
  religion_question_application: boolean
  // request_irish_exemption: boolean
  request_student_signature: boolean
  request_utility_bill: boolean
  request_utility_bill_second: boolean
  school_application: string
  // single_gender_school_notice: string
  is_manual_lottery: boolean
  is_enrol_photo_optional: boolean
  is_show_wating_list_number: boolean
  is_show_emergency_contact: boolean
  is_show_gender_question: boolean
  is_show_mother_tongue_ethnicity: boolean
  mother_tongue_ethnicity_in_application: boolean
  show_emergency_contact_in_application: boolean
  is_show_current_primary_school_question: boolean
  is_show_current_siblings: boolean
  is_birth_certificate: boolean
  birth_certificate_in_application: boolean
  is_random_assign_wating_list_number: boolean
  is_previous_school_report: boolean
  previous_school_report_in_application: boolean
  allow_asd_applications: boolean
  school_asd_description: string
  birth_certificate_description?: string
}

export interface OptionalData {
  is_country_of_origin: string,
  is_nationality: string,
  is_school_roll_number: string,
}

export interface postCustomData {
  id: string;
  customSettings: CustomData | undefined | null;
}

export const getCustomSettings = async (id: string): Promise<any> => {
  const res = await request({
    url: `school-settings/application-custom-settings-details/?application_uuid=${id}`,
    method: "get",
  });
  return res;
};

export const postCustomSettings = async ({
  id,
  customSettings,
}: postCustomData): Promise<any> => {
  const res = await request({
    url: `/school-settings/application-custom-settings-details/?application_uuid=${id}`,
    method: "put",
    data: {
      ...customSettings
    },
  });
  return res;
};

export interface postOptionalData {
  id: string;
  optionalSettings: OptionalData | undefined | null;
}

export const getOptionalSettings = async (id: string): Promise<any> => {
  const res = await request({
    url: `school-settings/application-custom-optional-settings-details/?application_uuid=${id}`,
    method: "get",
  });
  return res;
};

export const postOptionalSettings = async ({
  id,
  optionalSettings,
}: postOptionalData): Promise<any> => {
  const res = await request({
    url: `/school-settings/application-custom-optional-settings-details/?application_uuid=${id}`,
    method: "put",
    data: {
      ...optionalSettings
    },
  });
  return res;
};